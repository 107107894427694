import {BackLink} from "@/global/components/BackLink";
import {Button, Stack, Typography} from "@mui/material";
import {UserIcon} from "@/global/icons/UserIcon";
import {HeaderInfo} from "@/modules/students/components/StudentsByIin/HeaderInfo";
import {StudentIntegrationField} from "@/modules/students/components/StudentsByIin/StudentIntegrationField";
import React from "react";
import {BACKEND_URL} from "@/global/actions/apiVariables";
import {PictureAsPdf} from "@mui/icons-material";
import type {CertificateApplicationDto} from "@/global/api/gen/ts/CertificateApplicationDto";

interface Props {
    certificateInfo: CertificateApplicationDto,
    returnFirstStep: () => void,
}

export function EndTransactionNewCertificate({certificateInfo, returnFirstStep}: Props) {
    return (
        <>
            <BackLink
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
                sx={{
                    alignSelf: 'flex-start'
                }}
            />
            <Stack
                display={'flex'}
                flexDirection={'column'}
                sx={{
                    width: {lg: '60%', xs: '100%'},
                    borderRadius: '26px',
                    backgroundColor: '#FFFFFF',
                    padding: 3,
                    gap: '16px'
                }}
            >
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={2}
                >
                    <UserIcon size={61}/>
                    <Typography fontSize={'34px'}>
                        {`${certificateInfo.full_name ?? ''}`}
                    </Typography>
                </Stack>
                <HeaderInfo>
                    Данные курсанта
                </HeaderInfo>
                <Stack
                    flexDirection={'column'}
                    gap={1}
                >
                    <StudentIntegrationField
                        value={certificateInfo.full_name}
                        label={'ФИО:'}
                    />
                    <StudentIntegrationField
                        label={'ИИН курсанта:'}
                        value={certificateInfo.iin}
                    />
                    <StudentIntegrationField
                        label={'Автошкола:'}
                        value={certificateInfo.school_title}
                    />
                    <StudentIntegrationField
                        label={'Номер свидетельства:'}
                        value={certificateInfo.certificate_number}
                    />
                    <Stack
                        gap={2}
                        sx={{
                            marginTop: 4,

                        }}
                    >
                        <embed
                            src={`${BACKEND_URL}/certificate/ais-generate/by-iin/${certificateInfo.iin}/${certificateInfo.certificate_number}`}
                            height="375"
                            type="application/pdf"
                        />
                        <Button
                            href={`${BACKEND_URL}/certificate/ais-generate/by-iin/${certificateInfo.iin}/${certificateInfo.certificate_number}`}
                            variant={'contained'}
                            target={'_blank'}
                            sx={{
                                display: 'flex',
                                gap: 1,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingBlock: 4,
                                paddingInline: 4,
                                fontSize: '16px',
                                alignSelf: 'center',
                                textTransform: 'none'
                            }}
                        >
                            <PictureAsPdf/>
                            Скачать свидетельство
                        </Button>
                        <Typography fontWeight={'lighter'}>
                            Также ваше свидетельство было отправлено на почту
                            {' '}
                            <b>
                                {certificateInfo.email}
                            </b>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}