import {FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import {Controller, type SubmitHandler, useForm} from "react-hook-form";
import {StyledInput} from "@/modules/certificates/pages/buying/components/CertificateBuyingStepper/StyledInput";
import {EmailInputForm} from "@/global/components/FormInputs/EmailInputForm";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {zodRequiredString} from "@/global/types";
import type {CertificateApplicationCreateDto} from "@/global/api/gen/ts/CertificateApplicationCreateDto";
import {LoadingButton} from "@mui/lab";
import {BackLink} from "@/global/components/BackLink";
import React from "react";
import {PhoneInput} from "@/global/components/PhoneInput";

enum Types {
    'Simple',
    'Express'
}

const zodSchema = z.object({
    city: zodRequiredString,
    zip_code: zodRequiredString,
    phone: zodRequiredString,
    address: zodRequiredString,
    full_name: zodRequiredString,
    email: z.string().email('Неверный формат почтового ящика'),
    delivery_method: z.nativeEnum(Types),
});

type FormValues = z.infer<typeof zodSchema>

interface Props {
    handleStartTransaction: (data: Omit<CertificateApplicationCreateDto, 'certificate_number' | 'iin'>) => void;
    isPending: boolean,
    returnFirstStep: () => void,
}

export function StartTransactionOldCertificate({handleStartTransaction, returnFirstStep, isPending}: Props) {
    const {control, clearErrors, handleSubmit} = useForm<FormValues>({
        defaultValues: {
            delivery_method: Types.Express
        },
        resolver: zodResolver(zodSchema)
    });

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        handleStartTransaction(data);
    };

    return (
        <>
            <BackLink
                sx={{
                    alignSelf: 'flex-start',
                    fontSize: '18px',
                }}
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
            />
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{width: '100%'}}
            >
                <Stack
                    width={{lg: '60%', xs: '100%'}}
                    flexDirection={'column'}
                    gap={3}
                    sx={{
                        margin: '0 auto',
                        p: 6,
                        borderRadius: '16px',
                        background: '#F5F5F5',
                        gap: '16px'
                    }}
                >
                    <Stack gap={1}>
                        <Typography fontSize={'16px'}>
                            Укажите город (населенный пункт):
                        </Typography>
                        <StyledInput
                            control={control}
                            name={'city'}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Typography fontSize={'16px'}>
                            Индекс:
                        </Typography>
                        <StyledInput
                            control={control}
                            name={'zip_code'}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Typography fontSize={'16px'}>
                            Номер телефона:
                        </Typography>
                        <Controller
                            control={control}
                            name={'phone'}
                            render={({field: {onChange}}) => (
                                <PhoneInput
                                    sx={{
                                        borderRadius: '10px',
                                        '.MuiOutlinedInput-root': {
                                            backgroundColor: '#ebf0fb',
                                        },
                                        '.MuiOutlinedInput-input': {
                                            color: '#05304F',
                                            padding: '18px',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                        },
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'transparent'
                                        },
                                    }}
                                    onChange={phone => onChange(phone)}
                                />
                            )}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Typography fontSize={'16px'}>
                            Адрес доставки:
                        </Typography>
                        <StyledInput
                            control={control}
                            name={'address'}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Typography fontSize={'16px'}>
                            Имя получателя:
                        </Typography>
                        <StyledInput
                            control={control}
                            name={'full_name'}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Typography fontSize={'16px'}>
                            Укажите е-mail для получения трек-номера:
                        </Typography>
                        <EmailInputForm
                            resetError={clearErrors}
                            control={control}
                            name={'email'}
                            sx={{
                                borderRadius: '10px',
                                '.MuiOutlinedInput-root': {
                                    backgroundColor: '#ebf0fb',
                                },
                                '.MuiOutlinedInput-input': {
                                    color: '#05304F',
                                    padding: '18px',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent'
                                }
                            }}
                        />
                    </Stack>
                    <Controller
                        control={control}
                        name={'delivery_method'}
                        render={({field: {value, onChange}}) => (
                            <FormControl>
                                <Typography
                                    fontSize={'16px'}
                                    fontWeight={'600'}
                                    sx={{
                                        marginBottom: 2
                                    }}
                                >
                                    Способ доставки:
                                </Typography>
                                <RadioGroup
                                    row
                                    value={value}
                                    onChange={(_event, value) => onChange(+value)}
                                    sx={{
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <FormControlLabel
                                        value={Types.Simple}
                                        control={<Radio/>}
                                        label={(
                                            <Stack gap={1}>
                                                <Typography fontSize={16}>
                                                    Обычная доставка
                                                </Typography>
                                                <Stack>
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight={'bold'}
                                                    >
                                                        1000 тг.
                                                    </Typography>
                                                    <Typography
                                                        fontSize={14}
                                                        color={'#05304F80'}
                                                    >
                                                        до 10 рабочих дней
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        )}
                                    />
                                    <FormControlLabel
                                        value={Types.Express}
                                        control={<Radio/>}
                                        label={(
                                            <Stack gap={1}>
                                                <Typography fontSize={16}>
                                                    Экспресс доставка
                                                </Typography>
                                                <Stack>
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight={'bold'}
                                                    >
                                                        3000 тг.
                                                    </Typography>
                                                    <Typography
                                                        fontSize={14}
                                                        color={'#05304F80'}
                                                    >
                                                        до 5 рабочих дней
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        )}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                    />
                    <LoadingButton
                        loading={isPending}
                        type="submit"
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                        }}
                    >
                        Перейти к оплате
                    </LoadingButton>
                </Stack>
            </form>
        </>
    );
}