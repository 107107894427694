import {useCustomQuery} from "@/global/hooks/useCustomQuery";
import {certificateApplicationService, certificateService} from "@/global/api/gen";
import {CertificatesKeys} from "@/modules/certificates/services/certificates.keys";
import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import type {OmmitedUseQueryOptions} from "@/global/types";
import type {CertificateInfoDto} from "@/global/api/gen/ts/CertificateInfoDto";
import type {CertificateApplicationCreateDto} from "@/global/api/gen/ts/CertificateApplicationCreateDto";
import type {
    CertificateApplicationControllerSearchCertificateApplicationQueryParams
} from "@/global/api/gen/ts/certificate-applicationDTO/CertificateApplicationControllerSearchCertificateApplication";
import type {
    CertificateApplicationTrackingUpdateDto
} from "@/global/api/gen/ts/CertificateApplicationTrackingUpdateDto";
import type {CertificateApplicationBodyDto} from "@/global/api/gen/ts/CertificateApplicationBodyDto";

export const apiGetCertificateCheck = (studentId: string) => {
    return useCustomQuery({
        enabled: !!studentId,
        queryFn: () => certificateService.certificateControllerCheckCertificate({studentId})
            .then((res) => res.data),
        queryKey: CertificatesKeys.check(studentId),
    });
};

export const apiLastCertificateByIIn = (iin: string, options?: OmmitedUseQueryOptions<CertificateInfoDto>) => {
    return useCustomQuery({
        queryFn: () => certificateService.certificateControllerGetLastCertificateByIin({iin})
            .then((res) => res.data),
        queryKey: CertificatesKeys.lastCertificate(iin),
        ...options
    });
};

export const apiCreateCertificates = () => {
    return useCustomMutation({
        mutationFn: (data: { id: number }) => certificateService.certificateControllerChangeAppointment(data)
            .then((res) => res.data),
        queryKey: CertificatesKeys.all
    });
};

export const apiCreateCertificateApplication = () => {
    return useCustomMutation({
        mutationFn: (data: CertificateApplicationCreateDto) => certificateApplicationService.certificateApplicationControllerCreateCertificateApplication(data)
            .then((res) => res.data),
        queryKey: CertificatesKeys.certificatesApplication
    });
};

export const apiCertificateApplication = (id: number) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => certificateApplicationService.certificateApplicationControllerGetCertificateApplicationById({id})
            .then((res) => res.data),
        queryKey: CertificatesKeys.certificateApplicationDetail(id)
    });
};

export const apiCertificateApplicationSearch = (params: CertificateApplicationControllerSearchCertificateApplicationQueryParams) => {
    return useCustomQuery({
        queryFn: () => certificateApplicationService.certificateApplicationControllerSearchCertificateApplication(params)
            .then((res) => res.data),
        queryKey: CertificatesKeys.certificateApplicationSearch(params)
    });
};

export const apiUpdateTrackNumber = () => {
    return useCustomMutation({
        mutationFn: ({certificate_application_id, ...data}: CertificateApplicationTrackingUpdateDto & {
            certificate_application_id: number
        }) => certificateApplicationService.certificateApplicationControllerUpdateTrackNumber({certificate_application_id}, data)
            .then((res) => res.data),
        queryKey: CertificatesKeys.certificatesApplication
    });
};

export const apiChangeCertificate = () => {
    return useCustomMutation({
        mutationFn: ({certificate_application_id, ...data}: CertificateApplicationBodyDto & {
            certificate_application_id: number
        }) => certificateApplicationService.certificateApplicationControllerUpdateCertificateData({certificate_application_id}, data)
            .then((res) => res.data),
        queryKey: CertificatesKeys.certificatesApplication
    });
};