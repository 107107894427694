import {BackLink} from "@/global/components/BackLink";
import {Stack, Typography} from "@mui/material";
import {UserIcon} from "@/global/icons/UserIcon";
import {HeaderInfo} from "@/modules/students/components/StudentsByIin/HeaderInfo";
import {StudentIntegrationField} from "@/modules/students/components/StudentsByIin/StudentIntegrationField";
import {EmailInput} from "@/global/components/EmailInput";
import {PhoneInput} from "@/global/components/PhoneInput";
import {LoadingButton} from "@mui/lab";
import React, {useState} from "react";
import type {CertificateInfoDto} from "@/global/api/gen/ts/CertificateInfoDto";
import {toast} from "react-toastify";
import type {CertificateApplicationCreateDto} from "@/global/api/gen/ts/CertificateApplicationCreateDto";

interface Props {
    returnFirstStep: () => void,
    certificateInfo: CertificateInfoDto,
    onSubmit: (data: Omit<CertificateApplicationCreateDto, 'certificate_number' | 'iin'>) => void,
    isPending: boolean
}

export function StartTransactionNewCertificate({returnFirstStep, certificateInfo, isPending, onSubmit}: Props) {
    const [email, setEmail] = useState<{ email: string, isValid: boolean }>({email: "", isValid: false});
    const [phone, setPhone] = useState<{ phone: string, isValid: boolean }>({phone: "", isValid: false});

    const handleStartTransaction = () => {
        if (!email.isValid) {
            toast.error('Неверный формат почтового ящика');
            return;
        }
        if (!phone.isValid) {
            toast.error('Неверный формат телефона');
            return;
        }
        onSubmit({email: email.email, phone: phone.phone});
    };

    return (
        <>
            <BackLink
                sx={{
                    alignSelf: 'flex-start',
                    fontSize: '18px',
                }}
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
            />
            <Stack
                gap={2}
                sx={{
                    width: {xs: '100%', md: '50%'},
                    backgroundColor: 'white',
                    padding: {xs: 1.5, md: 6,},
                    borderRadius: '26px',
                }}
            >
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={2}
                >
                    <UserIcon size={61}/>
                    <Typography fontSize={'34px'}>
                        {`${certificateInfo.last_name} ${certificateInfo.first_name[0]}. ${certificateInfo.patronymic?.[0] || ''}.`}
                    </Typography>
                </Stack>
                <HeaderInfo>
                    Данные курсанта
                </HeaderInfo>
                <Stack
                    flexDirection={'column'}
                    gap={1}
                >
                    <StudentIntegrationField
                        value={(
                            <>
                                {certificateInfo.last_name}
                                {' '}
                                {certificateInfo.first_name}
                                {' '}
                                {certificateInfo.patronymic ?? ''}
                            </>
                        )}
                        label={'ФИО:'}
                    />
                    <StudentIntegrationField
                        label={'ИИН курсанта'}
                        value={certificateInfo.iin}
                    />
                    <StudentIntegrationField
                        label={'Автошкола'}
                        value={certificateInfo.school_title}
                    />
                </Stack>
                <Typography
                    alignSelf={'center'}
                    textAlign={'center'}
                >
                    Получение данных о
                    <br/>
                    {' '}
                    процессе обучения
                </Typography>
                <Typography
                    alignSelf={'center'}
                    textAlign={'center'}
                    fontSize={'2.5rem'}
                    fontWeight={'lighter'}
                    sx={{
                        paddingBlock: 1,
                        paddingInline: 8,
                        borderRadius: 25,
                        background: 'linear-gradient(84.38deg, #CDD4F1 -5.65%, #FFFFFF 41.55%, #C7E7F4 61.13%, #EEF0FD 87.7%)',
                    }}
                >
                    1000 тг.
                </Typography>
                <Stack gap={2}>
                    <Stack gap={1}>
                        <Typography>
                            Электронная почта (куда придут данные):
                        </Typography>
                        <EmailInput
                            placeholder={'example@demo.kz'}
                            fullWidth
                            onChange={(email, isValid) => setEmail({email, isValid})}
                            sx={{
                                borderRadius: '10px',
                                '.MuiOutlinedInput-root': {
                                    backgroundColor: '#ebf0fb',
                                },
                                '.MuiOutlinedInput-input': {
                                    color: '#05304F',
                                    padding: '18px',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent'
                                }
                            }}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Typography>
                            Номер телефона:
                        </Typography>
                        <PhoneInput
                            placeholder={'+7 (777) 777-77-77'}
                            fullWidth
                            onChange={(phone, isValid) => setPhone({phone, isValid})}
                            sx={{
                                borderRadius: '10px',
                                '.MuiOutlinedInput-root': {
                                    backgroundColor: '#ebf0fb',
                                },
                                '.MuiOutlinedInput-input': {
                                    color: '#05304F',
                                    padding: '18px',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent'
                                }
                            }}
                        />
                    </Stack>
                </Stack>
                <LoadingButton
                    loading={isPending}
                    onClick={handleStartTransaction}
                    variant={'contained'}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'normal',
                        fontSize: '18px',
                        marginBottom: 5,
                    }}
                >
                    Перейти к оплате
                </LoadingButton>
            </Stack>
        </>
    );
}