import type { AISDataDto } from './AISDataDto'
import type { TransactionDto } from './TransactionDto'

export const certificateApplicationDtoStatusEnum = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
} as const

export type CertificateApplicationDtoStatusEnum = (typeof certificateApplicationDtoStatusEnum)[keyof typeof certificateApplicationDtoStatusEnum]

export const certificateApplicationDtoDeliveryMethodEnum = {
  '0': 0,
  '1': 1,
} as const

export type CertificateApplicationDtoDeliveryMethodEnum =
  (typeof certificateApplicationDtoDeliveryMethodEnum)[keyof typeof certificateApplicationDtoDeliveryMethodEnum]

export type CertificateApplicationDto = {
  /**
   * @type number
   */
  id: number
  /**
   * @type number
   */
  status: CertificateApplicationDtoStatusEnum
  /**
   * @type object
   */
  certificate_data: AISDataDto
  /**
   * @type string
   */
  school_title: string
  /**
   * @type string
   */
  address?: string | null
  /**
   * @type string
   */
  category: string
  /**
   * @type string
   */
  iin: string
  /**
   * @type string
   */
  city?: string | null
  /**
   * @type string
   */
  zip_code?: string | null
  /**
   * @type string
   */
  phone: string
  /**
   * @type string
   */
  email: string
  /**
   * @type string
   */
  full_name?: string | null
  /**
   * @type number
   */
  delivery_method?: CertificateApplicationDtoDeliveryMethodEnum | null
  /**
   * @type string
   */
  document_url?: string | null
  /**
   * @type string
   */
  certificate_number: string
  /**
   * @type string
   */
  tracking_number?: string | null
  /**
   * @type number
   */
  transaction_id: number
  /**
   * @type number
   */
  user_id?: number | null
  transaction?: TransactionDto | null
  /**
   * @type string, date-time
   */
  created_at: string
  /**
   * @type string, date-time
   */
  updated_at: string
}