import {BackLink} from "@/global/components/BackLink";
import {Alert, Stack, Typography} from "@mui/material";
import {HeaderInfo} from "@/modules/students/components/StudentsByIin/HeaderInfo";
import {StudentIntegrationField} from "@/modules/students/components/StudentsByIin/StudentIntegrationField";
import {
    type CertificateApplicationDto,
    certificateApplicationDtoDeliveryMethodEnum
} from "@/global/api/gen/ts/CertificateApplicationDto";

interface Props {
    returnFirstStep: () => void,
    certificateInfo: CertificateApplicationDto
}

export function EndTransactionOldCertificate({returnFirstStep, certificateInfo}: Props) {
    return (
        <>
            <BackLink
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
                sx={{
                    alignSelf: 'flex-start'
                }}
            />
            <Stack
                display={'flex'}
                flexDirection={'column'}
                sx={{
                    width: {lg: '60%', xs: '100%'},
                    borderRadius: '26px',
                    backgroundColor: '#FFFFFF',
                    padding: 3,
                    gap: '16px'
                }}
            >
                <HeaderInfo>
                    Данные курсанта
                </HeaderInfo>
                <Stack
                    flexDirection={'column'}
                    gap={1}
                >
                    <StudentIntegrationField
                        value={certificateInfo.city}
                        label={'Город:'}
                    />
                    <StudentIntegrationField
                        label={'Индекс:'}
                        value={certificateInfo.zip_code}
                    />
                    <StudentIntegrationField
                        label={'Номер телефона:'}
                        value={certificateInfo.phone}
                    />
                    <StudentIntegrationField
                        label={'Адрес:'}
                        value={certificateInfo.address}
                    />
                    <StudentIntegrationField
                        label={'Имя получателя:'}
                        value={certificateInfo.full_name}
                    />
                    <StudentIntegrationField
                        label={'Email:'}
                        value={certificateInfo.email}
                    />
                    <StudentIntegrationField
                        label={'Способ доставки:'}
                        value={certificateInfo.delivery_method === certificateApplicationDtoDeliveryMethodEnum['1'] ? 'Экспресс доставка' : 'Обычная доставка'}
                    />
                </Stack>
                <Alert
                    severity={'success'}
                    sx={{
                        color: 'white',
                        backgroundColor: '#0E9A31',
                        '.MuiAlert-icon': {
                            color: 'white',
                        },
                        '.MuiSvgIcon-root': {
                            width: '28px',
                            height: '28px',
                        }
                    }}
                >
                    <Stack>
                        <Typography>
                            Платеж успешно совершен
                        </Typography>
                        <Typography fontSize={36}>
                            {certificateInfo.transaction?.price}
                            {" тг."}
                        </Typography>
                    </Stack>
                </Alert>
                <Typography variant={'body2'}>
                    После отправки свидетельства вам придет на почту сообщение с трек-номером
                </Typography>
            </Stack>
        </>
    );
}